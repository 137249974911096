import React from 'react'
import styles from "./index.module.css"
import GIf from "../../../../Assets/Profile/comingSoon.gif"

const History = () => {
  return (
    <div>
      <img src={GIf} alt="images"/>
    </div>
  )
}

export default History