export  const myEventsList = [
    {
        'title': 'Event 1',
        'allDay': false,
        'start': new Date(2022, 11, 15, 10, 0, 0),
        'end': new Date(2022, 11, 15, 14, 0, 0)
    },{
        'title': 'Event 2',
        'allDay': false,
        'start': new Date(2022, 11, 16, 17, 0, 0),
        'end': new Date(2022, 11, 16, 21, 0, 0)
    },{
        'title': 'Event 3',
        'allDay': false,
        'start': new Date(2022, 11, 24, 10, 0, 0),
        'end': new Date(2022, 11, 24, 12, 0, 0)
    },{
        'title': 'Event 4',
        'allDay': false,
        'start': new Date(2022, 11, 25, 13, 0, 0),
        'end': new Date(2022, 11, 25, 17, 0, 0)
    },
]