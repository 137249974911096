import React from 'react'
import styles from "./index.module.css"
import GIf from "../../../../Assets/Profile/comingSoon.gif"

const Performance = () => {
  return (
    <div>
      <img src={GIf} alt="image" />
    </div>
  )
}

export default Performance