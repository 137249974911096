import React, {useState} from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';

export default function CustomAccordion({ defaultOpen = false, keys, RouteModule, title, handleEvent, data, icon, ...props }) {
    const [open, setOpen] = React.useState(false);
    const [titles, setTitle] = useState("");
    const [dataTitle, setDataTitle] = useState("");
    const [selectedIndex, setSelectedIndex] = React.useState(1);

    const handleClick = (values) => {
        setTitle(values);
        setOpen(!open);
    };

    const handleListItemClick = (index) => {
        setSelectedIndex(index);
      };

    React.useEffect(() => {
        setOpen(defaultOpen);
    }, [defaultOpen])

    const handleRoute = (item, url) => {
        console.log(item,url, "ITEMs")
        handleEvent(item, url)
        
        // setTitle(prev => item);
        // setDataTitle(prev => title)
        // console.log(titles, "ITEMs")
    }

    console.log(RouteModule, keys?.key, "keykeykey")
    return (
        <>

        {/* {RouteModule?.key === keys?.key &&  */}
        
        <List
            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
            component="nav"
            aria-labelledby="nested-list-subheader"
        >
            <ListItemButton onClick={() => handleClick(title)}>
                <ListItemIcon>
                    <img src={icon} style={{ width: "30px" }} />
                </ListItemIcon>
                <div>
                    <p style={{ margin: '0px', width: "100%", overflowWrap: 'break-word', whiteSpace: 'initial', width: '170px' }}>{title}</p>
                </div>
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {data.map((item, idx) => {
                        return (
                            <ListItemButton sx={{ pl: 6 }} onClick={() => {handleRoute(item.title, item.url); handleListItemClick(idx)}} 
                            // style={(dataTitle == title && item.title == titles) ? {background: 'red'} : {}}
                            selected={titles === title && selectedIndex === idx}
                            >
                                {/* <ListItemIcon>
                            <StarBorder />
                        </ListItemIcon> */}
                                <img src={item.icon} style={{ width: '25px', marginRight: "10px" }} />
                                <ListItemText primary={item.title} />
                            </ListItemButton>
                        )
                    })}

                </List>
            </Collapse>
        </List>


        {/* } */}
        
        </>
    );
}
