import React, { useEffect, useState } from 'react'
import { api_token } from '../../Utils/Network';
import styles from './index.module.css';
import { useNavigate } from 'react-router-dom';

function DashboardCourse() {
    let navigate = useNavigate();
    const [courseLists, setCourseList] = useState([]);


    useEffect(() => {
        getCourseList();
    }, [])


    const getCourseList = () => [
        api_token
        .get(`/content/v1/course/listing`)
        .then((res) => {
            console.log(res);
            setCourseList(res.data.data);
        })
        .catch((err) => {
            console.log(err)
        })
    ]


    const PassData = (v) => {
        navigate(`/dashboard/courses/assign`, {state: {grade: v.grade, courseId: v.id}})
    }


    console.log(courseLists, "CourseList")

  return (
    <div>
        <h2>Courses</h2>

        <div className={styles.mainBox}>
            {courseLists && courseLists.map((v,i) => (
                <div className={styles.colorData} onClick={() => PassData(v)}>
                    <div>{v?.title}</div>
                    <div>{v?.student_count}</div>
                </div>
            ))}
        </div>

    </div>
  )
}

export default DashboardCourse